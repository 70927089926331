var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("transition", { attrs: { name: "slide" } }, [
    _c(
      "div",
      {
        staticClass: "oodrive-body px-3",
        class: {
          "container-layout": _vm.$screen.width >= 992,
          "container-mobile": _vm.$screen.width < 992,
        },
      },
      [
        _c(
          "div",
          {
            staticClass:
              "d-flex align-items-center justify-content-center w-100",
          },
          [
            _c(_vm.getLucideIcon("XCircle"), {
              tag: "component",
              staticClass: "mr-3",
              attrs: { color: "#EA4E2C", size: 32, "stroke-width": 3 },
            }),
            _c("div", [
              _c("div", { staticClass: "oodrive-title" }, [
                _vm._v(_vm._s(_vm.FormMSG(1, "Failure"))),
              ]),
              _c("div", { staticClass: "oodrive-sub-title" }, [
                _vm._v(
                  _vm._s(
                    _vm.FormMSG(
                      2,
                      "Your electronic signature was failed, please contact your administrator."
                    )
                  )
                ),
              ]),
            ]),
          ],
          1
        ),
      ]
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }